import styled, { keyframes } from 'styled-components'
import Img from 'gatsby-image'
import media from './media'

// HEADER
export const HeadContainer = styled.div`
  align-items: baseline;
  margin: 1.1rem 0 0.8em;
  display: grid;
  grid-template-columns: 0.4fr 1fr 0.4fr;
  .logo {
    /* margin: auto; */
  }
  .mobMenu {
    display: flex;
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 55%;
      &:focus {
        outline: none;
      }
    }
  }
  .menu-items {
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    input {
      font-size: 14px;
      line-height: 16px;
      font-family: 'neue-haas-grotesk-display-light';
      border: none;
      border-bottom: 1px solid black;
      height: 19px;
      &:focus {
        outline: none;
      }
    }
    .white {
      input {
        background-color: transparent;
        color: white;
        border-bottom: 1px solid white;
        &::placeholder {
          color: white;
        }
      }
    }
    datalist {
      width: 100vw;
      color: var(--black);
      background-color: var(--white);
    }
  }
  ${media.mobileL`
    grid-template-columns: 0.6fr 1fr 0.2fr;
  `}
  ${media.laptop`
    display: grid;
    grid-template-columns: 0.3fr 1fr;
    height: 60px;
    margin: 0 var(--gutter-m);
    padding: 0;
    .logo {
      padding: var(--gutter-s);
      margin: 0;
    }
    .menu-items {
      display: flex;
      justify-content: flex-end;
      a {
        margin-left: 40px;
        font-family: 'neue-haas-grotesk-display-med';
      }
      .thin {
        font-family: 'neue-haas-grotesk-display-light';
      }
      p {
        margin-left: 40px;
        &.divider {
          margin: 0 20px 0 50px;
          color: rgba(196, 196, 196, 0.5);
        }
      }
    }
  `}
`

export const TopHeaderText = styled.p`
  font-size: 12px;
  line-height: 14px;
  color: var(--white);
  font-family: 'neue-haas-grotesk-display';
`

export const MenuLink = styled.a`
  color: black;
  text-decoration: none;
  font-size: 12px;
  line-height: 14px;
  font-family: 'neue-haas-grotesk-display';
  border-bottom: none;
  &:focus {
    text-decoration: none;
  }
  &.mobMenu {
    font-size: 16px;
    line-height: 21px;
    display: block;
    margin: var(--gutter-s) 0;
  }
  &.bold {
    font-family: 'neue-haas-grotesk-display-bold';
  }
  &.cartLink {
    position: absolute;
    right: 2em;
    top: 1.8em;
    border-left: 1px solid #ececec;
    padding: 1.5em 0 0 1.5em;
    height: 26px;
    margin-top: -1.3em;
  }
  &.white {
    color: var(--white);
    transition: 0.3s ease-in-out;

    transition: all 0.3s;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    transition-delay: 0s;
  }
  &.noHover {
    &:hover {
      border-bottom: none;
    }
  }
  &.thin {
    font-family: 'neue-haas-grotesk-display-light';
    letter-spacing: 0.05em;
  }
  &.signin {
    font-size: 14px;
    line-height: 21px;
  }

  ${media.laptop`
    font-size: 14px;
    line-height: 16px;
    height: 40px;
    &.thin {
      font-family: 'neue-haas-grotesk-display-light';
    }
    background-image: linear-gradient(var(--blue), var(--blue)), linear-gradient(transparent, transparent);
    background-size: 0 2px, auto;
    background-repeat: no-repeat;
    background-position: center bottom;
    transition: all .3s ease-out;
    &:hover {
      color: var(--blue);
      text-decoration: none;
      background-size: 100% 2px, auto;
    }
    &.noHover {
      &:hover {
        background-size: 0px;
      }
    }
  `}
`

export const MenuLinkProd = styled.a`
  cursor: pointer;
  color: black;
  text-decoration: none;
  font-size: 12px;
  line-height: 14px;
  font-family: 'neue-haas-grotesk-display';
  border-bottom: none;
  &:focus {
    text-decoration: none;
  }
  &.mobMenu {
    font-size: 16px;
    line-height: 21px;
    display: block;
    margin: var(--gutter-s) 0;
  }
  &.bold {
    font-family: 'neue-haas-grotesk-display-bold';
  }
  &.cartLink {
    position: absolute;
    right: 2em;
    top: 1.8em;
  }
  &.white {
    color: var(--white);
    transition: 0.3s ease-in-out;

    transition: all 0.3s;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    transition-delay: 0s;
  }
  &.noHover {
    &:hover {
      border-bottom: none;
    }
  }
  &.thin {
    font-family: 'neue-haas-grotesk-display-light';
    letter-spacing: 0.05em;
  }
  &.signin {
    font-size: 14px;
    line-height: 21px;
  }

  ${media.laptop`
    font-size: 14px;
    line-height: 16px;
    height: 40px;
    &.thin {
      font-family: 'neue-haas-grotesk-display-light';
    }
    background-image: linear-gradient(var(--blue), var(--blue)), linear-gradient(transparent, transparent);
    background-size: 0 2px, auto;
    background-repeat: no-repeat;
    background-position: center bottom;
    transition: all .3s ease-out;
    &:hover {
      color: var(--blue);
      text-decoration: none;
      background-size: 100% 2px, auto;
    }
    &.noHover {
      &:hover {
        background-size: 0px;
      }
    }
  `}
`

export const MenuItem = styled.p`
  font-size: 14px;
  line-height: 16px;
  font-family: 'neue-haas-grotesk-display';
  letter-spacing: 0.05em;
  cursor: pointer;
  &.white {
    color: var(--white);
  }
  &.large {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: var(--gutter-s);
  }
  &.searchLink {
    margin: 2px 80px 0 0;
    img {
      width: 18px;
      /* margin-left: var(--gutter-button); */
    }
  }
  ${media.mobileL`
    &.searchLink {
      margin-right: 80px;
    }
  `}
  ${media.laptop`
    font-size: 14px;
    line-height: 16px;
    height: 40px;
    &.thin {
      font-family: 'neue-haas-grotesk-display-light';
    }
    background-image: linear-gradient(var(--blue), var(--blue)), linear-gradient(transparent, transparent);
    background-size: 0 2px, auto;
    background-repeat: no-repeat;
    background-position: center bottom;
    transition: all .5s ease-out;
    &:hover {
      background-size: 100% 2px, auto;
    }
    &.noHover {
      &:hover {
        background-size: 0px;
      }
    }
  `}
`

export const HeadDivider = styled.div`
  margin: -15px 20px 0 50px;
  border-right: 1px solid rgba(196, 196, 196, 0.5);
  height: 45px;
  max-height: 45px;
`

export const SocialLink = styled.a`
  color: black;
  text-decoration: none;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.05em;
  display: block;
  font-family: 'neue-haas-grotesk-display-light';
  margin: 15px 0;
`

export const LogoImg = styled.img`
  width: 60px;
  margin-top: -4px;
  &.mobMenu {
    margin: var(--gutter-m) 0 var(--gutter-m);
    width: 100px;
  }
  &.sideMenu {
    margin: var(--gutter-button) 0 var(--gutter-s);
    width: 110px;
  }
  &.mobNav {
    width: 100px;
    margin-left: 65px;
  }

  ${media.mobileL`
    &.mobNav {
      margin-left: 85px;
    }
  `}

  ${media.laptop`
    width: 160px;
    margin-bottom: -10px;
  `}
`

export const HeadImg = styled.img`
  width: 15px;
  margin-top: -4px;

  ${media.laptop`
    width: 20px;
    margin-top: -6px;
  `}
`

export const CartCounter = styled.span`
  color: var(--black);
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  float: right;
  margin: -10px;
  z-index: 20;
  background: var(--blue);
  width: 15px;
  height: 15px;
  position: relative;
`

// FOOTER
export const FooterText = styled.p`
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: var(--white);
  width: fit-content;
  margin-bottom: 25px;
  font-family: 'neue-haas-grotesk-display';
  &.heading {
    color: #c4c4c4;
    margin-bottom: 30px;
  }
  &.dark {
    color: #545454;
  }
`

export const FooterLink = styled.a`
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: var(--white);
  margin-bottom: 25px;
  display: block;
  text-decoration: none;
  width: fit-content;
  font-family: 'neue-haas-grotesk-display';
  transition: 0.3s ease-in-out;

  transition: all 0.3s;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transition-delay: 0s;
  &:focus {
    text-decoration: none;
  }
  &:hover {
    color: var(--blue);
    text-decoration: none;
    transition: 0.3s ease-in-out;

    transition: all 0.3s;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    transition-delay: 0s;
  }
`

export const SearchLink = styled.a`
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: var(--white);
  margin-bottom: 25px;
  display: flex;
  text-decoration: none;
  font-family: 'neue-haas-grotesk-display';
  color: #555555;
  line-height: 21px;
  .result {
    align-self: center;
  }
`

export const FooterExternalLink = styled.a`
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: var(--white);
  margin-bottom: 25px;
  width: fit-content;
  display: block;
  text-decoration: none;
  font-family: 'neue-haas-grotesk-display';
  transition: 0.3s ease-in-out;

  transition: all 0.3s;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transition-delay: 0s;
  &:hover {
    color: var(--blue);
    text-decoration: none;
    transition: 0.3s ease-in-out;

    transition: all 0.3s;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    transition-delay: 0s;
  }
`

export const FooterImg = styled.img`
  width: 120px;
  margin-bottom: 0;
  ${media.laptop`
    width: 180px;
    margin-bottom: 20px;
  `}
`

export const FooterPartner = styled.p`
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.05em;
  color: var(--white);
  font-family: 'neue-haas-grotesk-display';
`

// TEXT
export const HeadingText = styled.h1`
  font-size: 30px;
  line-height: 45px;
  letter-spacing: 0;
  color: var(--white);
  font-family: 'neue-haas-grotesk-display-bold';
  &.smallWidth {
    max-width: 750px;
  }
  &.xsmallWidth {
    max-width: 425px;
  }
  &.center {
    text-align: center;
  }
  &.black {
    color: var(--black);
  }
  &.spaced {
    margin: var(--gutter-m) var(--gutter-s);
  }
  &.smallSpaced {
    margin: var(--gutter-s);
  }
  &.easier {
    text-align: center;
    margin-top: 10px;
  }
  &.bottomSpace {
    margin: 0 0 var(--gutter-s);
    font-size: 20px;
    line-height: 30px;
  }
  &.smaller {
    font-size: 20px;
    line-height: 28px;
  }
  &.homeHero {
    width: 80%;
  }
  ${media.mobileL`
    &.aboutHeadWidth {
      max-width: 500px;
    }
  `}

  ${media.mobileL`
    &.easier {
      text-align: left;
    }
  `}
  
  ${media.laptop`
    font-size: 48px;
    line-height: 57px;
    margin-bottom: 20px;
    &.spaced {
      margin: var(--gutter-m);
    }
    &.easier {
      text-align: left;
    }
    &.bottomSpace {
      font-size: 48px;
      line-height: 57px;
      margin: 0 0 var(--gutter-m);
    }
    &.topSpace {
      margin: var(--gutter-button) 0 0;
    }
    &.smaller {
      font-size: 36px;
      line-height: 45px;
    }
    &.aboutHeadWidth {
      max-width: 650px;
    }
  `}
`

export const SubHeadingText = styled.h4`
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: var(--black);
  font-family: 'neue-haas-grotesk-display-bold';
  &.center {
    text-align: center;
  }
  &.filterHead {
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 20px;
  }

  ${media.laptop`
    font-size: 30px;
    line-height: 45px;
    &.smaller {
      font-size: 20px;
      line-height: 30px;
    }
  `}
`

export const BodyText = styled.p`
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.05em;
  color: var(--white);
  font-family: 'neue-haas-grotesk-display';
  &.smallWidth {
    max-width: 350px;
  }
  &.midText {
    max-width: 300px;
  }
  &.medWidth {
    max-width: 705px;
  }
  &.center {
    text-align: center;
    margin: 0 auto;
  }
  &.black {
    color: var(--black);
  }
  &.shorter {
    margin: 0 var(--gutter-s);
  }
  &.bold {
    font-family: 'neue-haas-grotesk-display-bold';
  }
  &.easier {
    text-align: center;
  }
  &.right {
    text-align: right;
    margin-right: var(--gutter-m);
  }
  &.blue {
    color: var(--blue);
  }

  ${media.mobileL`
    &.aboutBodWidth {
      max-width: 500px;
    }
    &.easier {
      text-align: left;
    }
  `}

  ${media.laptop`
    font-size: 18px;
    line-height: 27px;
    &.smallWidth {
      max-width: 650px;
    }
    &.shorter {
      margin: 0 var(--gutter-l);
    }
    &.easier {
      text-align: left;
    }
    &.midText {
      max-width: 480px;
    }
    &.contactLength {
      width: 550px;
    }
    &.aboutBodWidth {
      max-width: 640px;
    }
    &.forgot {
      font-size: 14px;
    }
    &.client {
      font-size: 16px;
    }
  `}
`

export const BodyLink = styled.button`
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.05em;
  color: var(--white);
  font-family: 'neue-haas-grotesk-display';
  text-decoration: underline;
  border: none;
  background: transparent;
  &.black {
    color: var(--black);
  }
  &.bold {
    font-family: 'neue-haas-grotesk-display-bold';
  }
  &.right {
    float: right;
  }

  ${media.laptop`
    font-size: 18px;
    line-height: 27px;
  `}
`

export const DetailsText = styled.p`
  font-size: 14px;
  line-height: 21px;
  &.heading {
    font-weight: bold;
    letter-spacing: 0;
    font-family: 'neue-haas-grotesk-display-bold';
  }
  &.right {
    text-align: right;
  }
  &.spaced {
    margin: var(--gutter-s) 0 calc(var(--gutter-s) + 20px);
  }
  &.prodDetails {
    margin: var(--gutter-s) 0;
  }
  &.quantity {
    margin-bottom: 0;
  }
  &.cartName {
    margin: 10px 0 0;
  }
`

export const DetailsLink = styled.a`
  font-size: 14px;
  line-height: 21px;
  text-decoration: none;
  &.blue {
    color: var(--blue);
    font-family: 'neue-haas-grotesk-display-bold';
  }
`

export const ShippingText = styled.p`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  font-family: 'neue-haas-grotesk-display-info';
  &.bold {
    font-family: 'neue-haas-grotesk-display-bold';
  }
`

export const AboutText = styled.p`
  font-size: 14px;
  line-height: 21px;
  &.selected {
    color: var(--blue);
    font-family: 'neue-haas-grotesk-display-bold';
  }

  ${media.laptop`
    font-size: 20px;
    line-height: 30px;
  `}
`

export const LargerHeading = styled.h1`
  font-size: 30px;
  line-height: 45px;
  word-wrap: break-word;
  font-family: 'neue-haas-grotesk-display-bold';

  ${media.laptop`
    font-size: 48px;
    line-height: 72px;
    &.bottomSpace {
      margin-bottom: 50px;
    }
  `}
`

// BUTTON
export const Button = styled.button`
  background: var(--blue);
  width: 150px;
  height: 40px;
  color: white;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.05em;
  border: none;
  font-family: 'neue-haas-grotesk-display';
  margin-right: calc(var(--gutter-s) + 20px);
  transition: 0.3s ease-in-out;

  transition: all 0.3s;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transition-delay: 0s;
  &:focus {
    outline: none !important;
  }
  &.clear {
    color: var(--black);
    border: 1px solid var(--light-grey);
    background: transparent;
  }
  &.text {
    display: flex;
    width: auto;
    height: auto;
    border: none;
    background: transparent;
    font-family: 'neue-haas-grotesk-display-bold';
    color: var(--black);
  }
  &.spaced {
    margin: var(--gutter-m) auto;
  }
  &.remove {
    width: 30px;
    height: 30px;
    color: var(--blue);
    font-family: 'neue-haas-grotesk-display-bold';
  }
  &.form-button {
    width: 100%;
    margin-right: 0;
  }
  &.more-button {
    margin: var(--gutter-m) 5%;
    width: 90%;
  }
  &.top-space {
    margin-top: var(--gutter-button);
  }
  &.bold {
    font-family: 'neue-haas-grotesk-display-bold';
  }
  &.hidden {
    display: none;
  }

  ${media.mobileL`
    &.filter {
      width: 100%;
    }
  `}

  ${media.laptop`
    width: 200px;
    height: 50px;
    font-size: 14px;
    line-height: 22px;
    &.remove {
      width: 50px;
      height: 40px;
    }
    &.form-button {
      width: 200px;
      margin-right: calc(var(--gutter-s) + 20px);
    }
    &.full {
      width: 100%;
      margin-right: 0;
    }
    &.large {
      width: 630px;
      margin-right: 0;
    }
    &.input {
      margin-top: 50px;
      width: 96%;
    }
    &.reg {
      width: 96%;
    }
    &:hover {
      background: var(--dark-blue);
      transition: 0.3s ease-in-out;

      transition: all 0.3s;
      transition-property: all;
      transition-duration: 0.2s;
      transition-timing-function: ease;
      transition-delay: 0s;
    }
    &.clear {
      &:hover {
        background: var(--blue);
        color: var(--white);
        transition: 0.3s ease-in-out;

        transition: all 0.3s;
        transition-property: all;
        transition-duration: 0.2s;
        transition-timing-function: ease;
        transition-delay: 0s;
      }
    }
    &.text {
      &:hover {
        background-color: transparent;
        cursor: pointer;
      }
    }
  `}
`

export const IncrementButton = styled.button`
  height: 50px;
  width: 70px;
  color: var(--black);
  background: transparent;
  border: none;
  font-family: 'neue-haas-grotesk-display';
  &.left {
    outline: none;
    border-right: 1px solid var(--light-grey);
    &:hover {
      border: 1px solid var(--blue);
    }
    &:focus {
      border: 1px solid var(--blue);
      outline: none;
    }
  }
  &.right {
    outline: none;
    border-left: 1px solid var(--light-grey);
    &:hover {
      border: 1px solid var(--blue);
    }
    &:focus {
      border: 1px solid var(--blue);
      outline: none;
    }
  }
`

// FORM
export const FormLabel = styled.label`
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
  font-family: 'neue-haas-grotesk-display-bold';
`

export const FormInput = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  width: 90%;
  height: 40px;
  margin-bottom: 20px;
  padding: 0 15px;
  border-radius: 0;
  font-family: 'neue-haas-grotesk-display';
  :focus {
    outline: none !important;
  }

  ${media.mobileL`
    width: 95%;
  `}

  ${media.laptop`
    height: 50px;
    width: 600px;
    &.full {
      width: 90%;
    }
    &.half {
      width: 80%;
    }
  `}
`

export const FormSelect = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  width: 93%;
  height: 40px;
  margin-bottom: 20px;
  padding: 0 15px;
  color: rgb(118, 118, 118);
  font-family: 'neue-haas-grotesk-display';
  :focus {
    outline: none !important;
  }

  ${media.mobileL`
    width: 95%;
  `}

  ${media.laptop`
    width: 600px;
    height: 50px;
    &.half {
      width: 90%;
    }
  `}
`

export const FormTextArea = styled.textarea`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 0;
  width: 90%;
  height: 80px;
  margin-bottom: 20px;
  resize: none;
  font-family: 'neue-haas-grotesk-display';
  padding: 25px 15px;
  :focus {
    outline: none !important;
  }

  ${media.mobileL`
    width: 95%;
  `}

  ${media.laptop`
    height: 100px;
    width: 600px;
  `}
`

export const FormError = styled.span`
  color: red;
  font-family: 'neue-haas-grotesk-display';
  position: absolute;
  margin-top: -40px;
  margin-left: -15%;
  &.select {
    position: absolute;
    margin-top: -105px;
    margin-left: 52%;
  }
  &.reg {
    position: inherit;
    margin-top: 0;
    margin-left: 0;
  }
  ${media.laptop`
    margin-top: -50px;
    margin-left: -5%;
    &.select {
      position: absolute;
      margin-top: -120px;
      margin-left: 33%;
    }
  `}
`

// DIVIDER
export const Divider = styled.div`
  margin: 0 5px;
  border-bottom: 1px solid #d8d8d8;
  height: 1px;
  &.spaced {
    margin: var(--gutter-s) 0 calc(var(--gutter-s) + 15px);
  }
  &.low {
    margin-top: calc(var(--gutter-s) + 15px);
  }
  &.light {
    border-bottom: 1px solid #f9f9f9;
  }
  &.dark {
    border-bottom: 1px solid #555555;
  }
  &.nav {
    border-bottom: 1px solid #C4C4C4;
    margin: 0;
  }
  &.smallSpace {
    margin: var(--gutter-s) 0;
  }
  &.footer {
    border-bottom: 1px solid #181818;
  }
  &.bottomSpace {
    margin: 0 0 var(--gutter-button);
  }
`

// PRODUCT GRID
export const Grid = styled.div`
  display: grid;
  margin: 0 var(--gutter-s) var(--gutter-s);
  grid-template-columns: repeat(2, 1fr);
  gap: var(--gutter-s) var(--gutter-xs);
  text-align: center;
  a {
    :hover {
      text-decoration: none;
    }
  }
  &.bottom-space {
    margin-bottom: 6em;
  }

  ${media.mobileL`
    grid-template-columns: repeat(3, 1fr);
    gap: var(--gutter-m) var(--gutter-s);
    a {
      margin: 0 auto;
    }
  `}

  ${media.tablet`
    grid-template-columns: repeat(2, 1fr);
    gap: var(--gutter-m) var(--gutter-s);
  `}

  ${media.laptop`
  margin: 0;
    grid-template-columns: repeat(3, 1fr);
    &.related {
      margin: 40px var(--gutter-l) var(--gutter-l);
    }
  `}
`

export const Product = styled.div`
  justify-self: center;
  display: flex;
  flex-direction: column;
  background: var(--off-white);
  width: 160px;
  height: 275px;
  padding: 10px;

  ${media.laptop`
    width: 250px;
    height: 400px;
    padding: 30px;
  `}
`

export const Title = styled.span`
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  text-align: center;
  color: var(--black);
  font-family: 'neue-haas-grotesk-display';

  ${media.laptop`
    font-size: 14px;
    line-height: 21px;
  `}
`

export const PriceTag = styled.span`
  font-family: 'neue-haas-grotesk-display-bold';
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.05em;
  color: var(--black);
  margin: 20px 0;

  ${media.laptop`
    margin: 40px 0;
  `}
`

export const ProductImgContainer = styled.div`
  background-color: white;
  height: 115px;
  width: 145px;
  margin: 0 auto 40px;

  ${media.laptop`
    height: 195px;
    width: 240px;
    margin-bottom: 40px;
    transition: 0.3s ease-in-out;

    transition: all 0.3s;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    transition-delay: 0s;
    &:hover {
      opacity: 0.5;
      transition: .3s ease-in-out;

transition: all .3s;
transition-property: all;
transition-duration: 0.2s;
transition-timing-function: ease;
transition-delay: 0s;
    }
  `}
`

export const ProductImage = styled(Img)`
  max-width: 100%;
  margin: 0;
  padding: 0;
  height: 105px;
  picture {
    img {
      object-fit: contain !important;
    }
  }

  ${media.laptop`
    height: 195px;
  `}
`

export const ProductSearchImage = styled(Img)`
  width: 50px;
  height: 50px;
  margin-right: var(--gutter-s);
  picture {
    img {
      object-fit: contain !important;
    }
  }
`

export const BuyLink = styled.a`
  font-family: 'neue-haas-grotesk-display-bold';
  font-size: 14px;
  line-height: 21px;
  color: var(--blue);
  text-decoration: none;
  text-align: center;

  ${media.laptop`
    font-size: 16px;
    line-height: 27px;
  `}
`

const showLink = keyframes`
  from {
    position: absolute;
  }
  to {
    position: relative;
  }
`

const hideLink = keyframes`
  from {
    position: relative;
  }
  to {
    position: absolute;
  }
`

export const ShareButton = styled.button`
  background-color: var(--blue);
  width: 35px;
  height: 35px;
  border: none;
  padding: 10px;
  border-radius: 50%;
  margin-right: 1em;
  position: absolute;
  animation: ${hideLink} 1s linear backwards;
  transition: 0.3s ease-in-out;

  transition: all 0.3s;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transition-delay: 0s;
  img {
    height: 15px;
    margin: 0;
    padding: 0;
    width: auto;
  }
  &:focus {
    border: none;
    outline: none;
  }
  &.fb {
    z-index: -1 !important;
  }
  &.twitter {
    z-index: -2 !important;
  }
  &.insta {
    z-index: -3 !important;
  }
  &.shown {
    animation: ${showLink} 1s linear forwards;
  }
  &.shown:nth-child(1) {
    animation-delay: 0s;
  }
  &.shown:nth-child(2) {
    animation-delay: 0.2s;
  }
  &.shown:nth-child(3) {
    animation-delay: 0.4s;
  }
  &:hover {
    background-color: var(--dark-blue);
    transition: 0.3s ease-in-out;

    transition: all 0.3s;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    transition-delay: 0s;
  }
`

// PRODUCT PAGE
export const LargeProdImg = styled(Img)``
export const ProductPrice = styled.h4`
  font-size: 30px;
  line-height: 45px;
  font-family: 'neue-haas-grotesk-display-bold';
`

// BREADCRUMB
export const CrumbCon = styled.div`
  padding: 0 var(--gutter-s);
  height: 35px;
  background: var(--off-white);
  font-family: 'neue-haas-grotesk-display-light';
  nav {
    font-size: 8px;
    line-height: 9px;
    ol {
      list-style: none;
      display: flex;
      padding-inline-start: 0;
      p {
        margin-top: 12px;
        &.blue {
          font-size: 26px;
          margin-top: -1.5px;
          color: var(--blue);
        }
      }
      li {
        margin-top: 12px;
        display: flex;
        a {
          color: var(--black);
          text-decoration: none;
          margin: 0 10px;
          p {
            &.backArrow {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  ${media.laptop`
    padding: 0 calc(var(--gutter-s) + 10px);
    nav {
      font-size: 14px;
      line-height: 21px;
      ol {
        padding-inline-start: var(--gutter-s);
        p {
          margin-top: 6px;
        &.blue {
          margin-top: -2px;
        }
        }
        li {
          margin-top: 6px;
          a {
          p {
            &.backArrow {
              font-size: 24px;
            }
          }
        }
        }
      }
    }
  `}
`

// CART
export const CartTable = styled.table`
  width: 100%;
  text-align: left;
  font-family: 'neue-haas-grotesk-display';
  th {
    font-size: 12px;
    line-height: 18px;
    font-family: 'neue-haas-grotesk-display-bold';
  }
  td {
    &.productInfo {
      width: 45%;
    }
  }

  ${media.laptop`
    th {
      padding: 0 0 calc(var(--gutter-s) + 20px);
      font-size: 14px;
      line-height: 21px;
      font-family: 'neue-haas-grotesk-display-bold';
    }
    td {
      &.productInfo {
        width: auto;
        display: flex;
        a {
          text-align: start;
        }
        .tableImage {
          width: 90px;
          height: 70px;
          background-color: white;
          margin-right: var(--gutter-s);
          img {
            max-width: 90px;
            max-height: 70px;
          }
        }
      }
    }
  `}
`

export const CartSummary = styled.div`
  background: var(--off-white);
  padding: 30px 15px;
  font-family: 'neue-haas-grotesk-display';

  ${media.laptop`
    padding: calc(var(--gutter-s) + 20px);
  `}
`

export const QuantityInput = styled.input`
  width: 30px;
  height: 30px;
  border: 1px solid var(--light-grey);
  text-align: center;
  font-family: 'neue-haas-grotesk-display';

  &.long {
    width: 60px;
    height: auto;
    border: none;
  }

  ${media.laptop`
    width: 50px;
    height: 40px;
    &.cart {
      height: 37px;
      width: 45px;
    }
  `}
`

// FOOTER FORM
export const FormCont = styled.div`
  font-family: 'neue-haas-grotesk-display';
  form {
    input {
      color: var(--form-colour);
      background-color: transparent;
      border: none;
      margin: 0 0 var(--gutter-s);
      border-bottom: 1px solid #dadada;
      font-size: 13px;
      line-height: 15px;
      width: 100%;
      padding: 0 0 5px;
    }
    input:focus {
      outline: none !important;
    }
    ::placeholder {
      color: var(--form-colour);
    }
  }
  .error {
    font-size: 0.8rem;
    color: red;
    position: absolute;
    right: 6em;
  }
`

// FAQ
export const FAQCont = styled.div`
  margin: var(--gutter-m) var(--gutter-s);
  .rc-collapse {
    background-color: var(--white);
    border: none;
    border-radius: 0;
    font-weight: normal;
    .rc-collapse-item {
      padding: 1em 0 1em;
      border-top: none;
      border-bottom: 1px solid #d8d8d8;
      .rc-collapse-header {
        padding: 0.5em 0;
        font-size: 12px;
        line-height: 21px;
        font-family: 'neue-haas-grotesk-display-bold';
        color: var(--black);
        i {
          margin-right: 20px;
        }
      }
      .rc-collapse-header:focus {
        outline: none;
      }
      .rc-collapse-content {
        font-size: 12px;
        line-height: 21px;
        background-color: var(--white);
        color: var(--black);
        margin-left: 2em;
        width: 300px;
      }
    }
  }

  ${media.mobileL`
    .rc-collapse {
      .rc-collapse-item {
        .rc-collapse-header {
          font-size: 14px;
        }
        .rc-collapse-content {
          font-size: 14px;
          width: 500px;
        }
      }
    }
  `}

  ${media.laptop`
    margin: var(--gutter-m);
    .rc-collapse {
      .rc-collapse-item {
        .rc-collapse-header {
          font-size: 18px;
          line-height: 27px;
        }
        .rc-collapse-content {
          font-size: 16px;
          line-height: 21px;
          width: 900px;
        }
      }
    }
  `}
`

const showSearch = keyframes`
  from {
    height: 0;
  }
  to {
    height: 30em;
  }
`

const hideSearch = keyframes`
  from {
    height: 30em;
  }
  to {
    height: 0em;
  }
`

const showSearchBar = keyframes`
  from {
    opacity: 0;
    height: 0px;
  }
  to {
    opacity: 1;
    height: 60px;
  }
`

export const SearchHeader = styled.div`
  /* min-height: 60px; */
  position: absolute;
  top: 0;
  width: 100vw;
  display: grid;
  grid-template-columns: 2fr 0.5fr;
  background-color: white;
  z-index: 20;
  form {
    margin-left: 50px;
    display: flex;
    img {
      width: 15px;
      height: 15px;
      margin: 20px 0;
      padding: 0;
    }
  }
  input {
    margin: 10px var(--gutter-s);
    height: 30px;
    width: 180px;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid var(--black);
    :focus {
      outline: none !important;
    }
  }
  button {
    background: none;
    border: none;
    :focus {
      outline: none !important;
    }
  }
  &.white {
    position: inherit;
  }
  animation: ${showSearchBar} 0.5s linear forwards;
  overflow: hidden;

  ${media.laptop`
    position: inherit;
    width: auto;
    height: 60px;
    display: grid;
    grid-template-columns: 2fr 2fr 2fr;
    padding: 0 var(--gutter-m);
    img {
      margin: 16px 20px;
    }
    form {
      margin-left: 0;
      display: flex;
      img {
        width: 15px;
        height: 15px;
        margin: 20px 0;
        padding: 0;
      }
    }
    input {
      height: 30px;
      width: 100%;
      border: none;
      border-bottom: 2px solid var(--black);
      :focus {
        outline: none !important;
      }
    }
  `}
`

export const SearchResults = styled.div`
  width: 100%;
  max-width: 80vw;
  z-index: 9;
  background-color: var(--white);
  position: absolute;
  top: 4em;
  left: 0;
  padding: var(--gutter-button) 10% var(--gutter-button);
  overflow: hidden;
  .more {
    display: flex;
    p {
      margin-right: var(--gutter-s);
      &.blue {
        color: var(--blue);
      }
    }
  }
  animation: ${hideSearch} 0.5s linear forwards;

  &.showing {
    animation: ${showSearch} 0.5s linear forwards;
  }

  ${media.laptop`
    max-width: 50vw;
    padding: var(--gutter-l) 25% var(--gutter-m) 25%;
  `}
`

export const SearchBlack = styled.div`
  background-color: var(--black);
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 8;
  opacity: 0.5;
`
