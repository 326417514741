import React, { useState } from 'react'
import styled from 'styled-components'
import {
  FooterText,
  Button,
  Divider,
  FooterImg,
  FooterLink,
  FooterExternalLink,
  FormCont,
} from '../../styles'
import media from '../../styles/media'
import WhiteLogo from '../../images/white-logo.webp'
import { Formik } from 'formik'
import addToMailchimp from 'gatsby-plugin-mailchimp'

const FooterCon = styled.div`
  background-color: var(--black);
  color: var(--white);
  padding-bottom: 1.5em;
  .top-footer {
    display: block;
    padding: var(--gutter-m) var(--gutter-s) var(--gutter-s);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 0.6fr 1fr 0.3fr;
    .logos {
      margin: 0 auto;
      p {
        text-align: center;
      }
    }
    .shop {
      grid-row: 3;
    }
    .connect {
      grid-row: 4;
      margin-top: 20px;
    }
    .news {
      grid-row: 2;
    }
    form {
      input {
        border-radius: 0;
        color: white;
      }
    }
  }
  .bottom-footer {
    display: flex;
    max-height: 40px;
    justify-content: center;
    p {
      margin-top: 17px;
      margin-bottom: 0;
    }
  }
  ${media.laptop`
    .top-footer {
      display: grid;
      padding: var(--gutter-m) var(--gutter-m);
      grid-template-columns: 1fr 0.8fr 1fr 1fr;
      grid-template-rows: 1fr;
      .logos {
        margin: 0;
        p {
          text-align: left;
        }
      }
      .shop {
        grid-row: 1;
        grid-column: 2;
      }
      .connect {
        margin-top: 0;
        grid-row: 1;
        grid-column: 3;
      }
      .news {
        grid-row: 1;
        grid-column: 4;
      }
    }
    .bottom-footer {
      display: flex;
      max-height: 40px;
      justify-content: flex-end;
    margin: 0 var(--gutter-m);
      p {
        margin-top: 17px;
        margin-bottom: 0;
      }
    }
  `}
`

const Footer = () => {
  const [showFormSuccess, setFormSuccess] = useState(false)

  return (
    <FooterCon>
      <div className="top-footer">
        <div className="logos">
          <a href="/">
            <FooterImg src={WhiteLogo} alt="logo" />
          </a>
        </div>
        <div className="shop">
          <FooterText className="heading">Quza-IT</FooterText>
          <FooterLink href="/about">About Us</FooterLink>
          <FooterLink href="/products">Our Products</FooterLink>
          <FooterLink href="/contact">Contact</FooterLink>
          <FooterLink href="/faq">FAQ</FooterLink>
          <FooterLink href="/termsandconditions">
            Terms &amp; Conditions
          </FooterLink>
          <FooterLink href="/shipping">Shipping Information</FooterLink>
          <FooterLink href="/showcase">HP Brand Showcase</FooterLink>
        </div>
        <div className="connect">
          <FooterText className="heading">Connect</FooterText>
          <FooterExternalLink href="mailto: info@quza.co.za" target="_blank">
            info@quza.co.za
          </FooterExternalLink>
          <FooterExternalLink href="tel:0126535319" target="_blank">
            +27(0) 12 653 5319
          </FooterExternalLink>
          <FooterExternalLink
            href="https://www.google.com/maps/place/28+Aalwyn+Cres,+Eldoraigne,+Centurion,+0157/@-25.85171,28.1588973,17z/data=!3m1!4b1!4m5!3m4!1s0x1e95645f09b2bbb9:0xcd57bf013f980400!8m2!3d-25.85171!4d28.161086"
            target="_blank"
            rel="noopener noreferrer"
          >
            28 Aalwyn Cres, Eldorange, Centurion
          </FooterExternalLink>
          <FooterExternalLink
            href="https://www.facebook.com/Quza.IT"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook
          </FooterExternalLink>
        </div>
        <div className="news">
          <FooterText className="heading">
            Subscribe to future updates from Quza-IT
          </FooterText>
          <FormCont>
            <div className="form">
              <Formik
                initialValues={{ email: '', subscribe: false }}
                validate={values => {
                  let errors = {}
                  if (!values.email) {
                    errors.email = 'Required'
                  }
                  return errors
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  setSubmitting(false)
                  setFormSuccess(true)
                  setTimeout(function() {
                    setFormSuccess(false)
                  }, 5000)
                  resetForm({})
                  addToMailchimp(values.email)
                  resetForm({})
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <input
                      className="email"
                      type="email"
                      name="email"
                      placeholder="Enter email address *"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <span className="error">
                      {errors.email && touched.email && errors.email}
                    </span>
                    {!showFormSuccess && (
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        className="form-button"
                      >
                        Sign Up
                      </Button>
                    )}
                  </form>
                )}
              </Formik>
            </div>
          </FormCont>
          {showFormSuccess && (
            <FooterText>Thank you for subscribing</FooterText>
          )}
        </div>
      </div>
      <Divider className="footer" />
      <div className="bottom-footer">
        <FooterText className="heading dark">
          &copy; 2020 Quza - IT. All Rights Reserved.
        </FooterText>
      </div>
    </FooterCon>
  )
}

export default Footer
