import React, { useContext } from 'react'
import styled, { keyframes } from 'styled-components'
import {
  Divider,
  LogoImg,
  MenuLink,
  SocialLink,
  MenuLinkProd,
} from '../../styles'
import Logo from '../../images/black-logo.webp'
import { navigate } from 'gatsby'
import StoreContext from '~/context/StoreContext'

import media from '../../styles/media'

const showMenu = keyframes`
  from {
    left: -55vw;
  }
  to {
    left: 0;
  }
`
const hideMenu = keyframes`
  from {
    left: 0;
  }
  to {
    left: -55vw;
  }
`
const overlayShow = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const StyledBurger = styled.button`
  position: absolute;
  left: 1.5rem;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 1.12rem;
  display: ${({ open }) => (open ? 'none' : 'flex')};
  transition: all 0.3s linear;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 9;
  &:focus {
    outline: none;
  }
  border-right: 1px solid #ececec;
  margin: -1em 0em 0 0em;
  padding: 1em 3em 1em 0em;
  height: 46px;
  div {
    background-color: black;
    width: 1.4rem;
    height: 2px;
    margin: 0;
    border-radius: 0;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }
  ${media.tablet`
    display: none;
  `}
`

const StyledMenu = styled.nav`
  flex-direction: column;
  justify-content: center;
  background: white;
  height: 100vh;
  width: 55vw;
  text-align: left;
  position: fixed;
  overflow: scroll;
  top: 0;
  left: 0;
  z-index: 22;
  .linkItems {
    padding: var(--gutter-s);
  }
  .social {
    margin: 4rem 1rem 1rem;
  }

  &.hidden {
    animation-delay: 0.5;
    animation: ${hideMenu} 0.2s ease-in forwards;
  }

  &.showing {
    /* display: block; */
    animation: ${showMenu} 0.3s ease-in forwards;
  }

  ${media.laptopL`
    .social {
      margin: 2rem 1rem 1rem;
      margin-bottom: 3rem;
    }
  `}
`

const BackgroundCon = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  margin-top: -18px;
  transition: opacity 0.5s ease-in-out;
  z-index: 21;
  display: none;
  &.showing {
    display: block;
    animation-delay: 0.5;
    animation: ${overlayShow} 0.3s linear forwards;
  }
`

const Burger = ({ open, setOpen }) => {
  const context = useContext(StoreContext)

  const navigateProducts = () => {
    navigate('/products')
    context.updateFilterType('all')
    setOpen(!open)
  }

  return (
    <>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>
      
      <StyledMenu open={open} className={open ? 'showing' : 'hidden'}>
        <div className="linkItems">
          <MenuLink href="/" onClick={() => setOpen(!open)}>
            <LogoImg className="sideMenu" src={Logo} alt="logo" />
          </MenuLink>
          <MenuLink
            className="mobMenu bold"
            href="/"
            onClick={() => setOpen(!open)}
          >
            Home
          </MenuLink>
          <Divider className="" />
          <MenuLinkProd className="mobMenu bold" onClick={navigateProducts}>
            Our Products
          </MenuLinkProd>
          <Divider className="" />
          <MenuLink
            className="mobMenu bold"
            href="/about"
            onClick={() => setOpen(!open)}
          >
            About Us
          </MenuLink>
          <Divider className="" />
          <MenuLink
            className="mobMenu bold"
            href="/contact"
            onClick={() => setOpen(!open)}
          >
            Contact
          </MenuLink>
          <Divider className="" />
        </div>
        <div className="social">
          <MenuLink className="mobMenu signin" href="/account/login">
            Sign in
          </MenuLink>
          <SocialLink
            href="https://www.facebook.com/Quza.IT"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook
          </SocialLink>
          <SocialLink
            href="mailto: info@quza.co.za"
            target="_blank"
            rel="noopener noreferrer"
          >
            info@quza.co.za
          </SocialLink>
        </div>
      </StyledMenu>
      <BackgroundCon
        open={open}
        className={open ? 'showing' : ''}
        onClick={() => setOpen(!open)}
      ></BackgroundCon>
    </>
  )
}

export default Burger
